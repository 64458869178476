.btn {
    @apply h-[42px] md:h-[52px] rounded-[14px] md:rounded-[16px] uppercase px-[14px] md:px-9 font-medium text-sm md:text-base;
}

.primary-btn {
    @apply text-white bg-primary;
}

.primary-outline-btn {
    @apply border border-primary text-primary bg-white h-11 md:h-[50px];
}

.info-contained-btn {
    @apply bg-white text-info;
}
