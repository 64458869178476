@import "fonts.css";
@import "button.css";
@import "swiper.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #00a593;
  --primary-light: #00d972;
  --secondary: #656565;
  --info: #1773c6;
  --dark-blue: #0a2136;
  --dark-blue-100: #061c25;
  --dark-green: #135269;
  --dark-info: #0e2f4c;
  --yellow: #fcb000;
  --background: #ffffff;
  --foreground: #171717;
}

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --primary: #00A593;*/
/*    --secondary: #d3d3d3;*/
/*    --info: #1773C6;*/
/*    --dark-blue: #0A2136;*/
/*    --background: #0a0a0a;*/
/*    --foreground: #ededed;*/
/*  }*/
/*}*/

* {
  word-break: normal !important;
}

.container {
  max-width: 1328px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.bg-image{
  background-image: url('../../public/images/simplyhealth.jpg');
  /* background-position: right -200px bottom; */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: c;
  /* back */
}

a:hover {
  cursor: pointer;
  color: var(--primary);
}

.bg-right-bottom {
  background-position: right, bottom;
}

.vertical-milestone:after {
  content: "";
  position: absolute;
  height: 100%;
  border-style: dashed;
  border-color: var(--primary-light);
  border-width: 2px;
  top: calc(50% + 5px);
}

@media (max-width: 640px) {
  .hero-section-title {
    font-size: 35px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .investor-store-section {
    margin-top: 350px !important;
  }
  .invest-hero-section {
    width: 100% !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .investor-store-section {
    margin-top: 250px !important;
  }
  .invest-hero-section {
    width: 100% !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 640px) {
  .investor-store-section {
    margin-top: 145px !important;
  }
  /* .invest-hero-section {
    width: 100% !important;
  } */
}

.goog-te-gadget img {
  display: none !important;
}
body > .skiptranslate {
  display: none;
}
body {
  top: 0px !important;
}

#goog-gt-tt {
  display: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}